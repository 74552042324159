import apiClient from './apiClient'
import store from './store'

async function processError(r) {
	if (r.status !== 200) {
		let o = null
		try {
			o = await r.json()
		} catch {
			o = await r.text()
		}
		throw o.error || o
	}
}

function initGAPI() {
	return new Promise(resolve => {
		window.gapi.load('auth2', () => {
			resolve()
		})
	})
}

async function userChanged(googleUser) {
	if (googleUser) { // TODO called multiple times at once
		const json = await apiClient.authUser(googleUser.getAuthResponse().id_token)
		if (json.status && json.status !== 200) return store.signOut()
		store.signIn(
			googleUser.getBasicProfile().getName(),
			googleUser.getBasicProfile().getGivenName(),
			googleUser.getBasicProfile().getEmail(),
			googleUser.getBasicProfile().getImageUrl(),
			googleUser.getAuthResponse().id_token,
			googleUser.getAuthResponse().expires_in,
			json && json.roles && json.roles.includes('verified'),
			json
		)
	} else {
		store.signOut()
	}
}

function refreshValues(auth2) {
	if (auth2 && auth2.isSignedIn.get()) {
		userChanged(auth2.currentUser.get())
	} else {
		userChanged(null)
	}
}

const auth = {
	async init() {
		await this.fetchSessionUser()
		if (!store.state.user) {
			await this.initGoogleJWTAuth()
		}
	},

	async fetchSessionUser() {
		const json = await apiClient.authUser()
		if (json.status && json.status !== 200) return
		store.signIn(
			json.name,
			null,
			json.email,
			null,
			null,
			null,
			json && json.roles && json.roles.includes('verified'),
			json
		)
	},

	async initGoogleJWTAuth() {
		console.log('GAPI INIT')
		await initGAPI()
		const clientId = process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID
		const auth2 = await window.gapi.auth2.init({ clientId })
		window.googleAuth = auth2
		store.setGoogleAuth(auth2)
		auth2.isSignedIn.listen(() => refreshValues(auth2))
		auth2.currentUser.listen(userChanged)
		if (auth2.isSignedIn.get() === false) {
			auth2.signIn()
			setTimeout(function () {
				if (auth2.isSignedIn.get() === true) {
					auth2.currentUser.get().reloadAuthResponse()
				}
			}, 60 * 1000// refresh in 1 min
			)
		}
		refreshValues(auth2)
		setInterval(function () {
			if (auth2.isSignedIn.get() === true) {
				auth2.currentUser.get().reloadAuthResponse()
			}
		}, 5 * 60 * 1000
		) // refresh every 5 mins
	},

	async logout() {
		if (store.state.user.id_token) {
			await store.state.googleAuth.signOut()
			store.signOut()
		} else {
			await fetch('/auth/logout')
			store.signOut()
			await this.initGoogleJWTAuth()
		}
	},

	async submitChangePwForm(token, password) {
		const body = new URLSearchParams({ token, password })
		const r = await fetch('/auth/changepw', { method: 'POST', body })
		await processError(r)
	},

	async submitForgotForm(email) {
		const body = new URLSearchParams({ email })
		const r = await fetch('/auth/forgot', { method: 'POST', body })
		await processError(r)
	},

	async submitLoginForm(email, password) {
		const body = new URLSearchParams({ email, password })
		const r = await fetch('/auth/login', { method: 'POST', body })
		await processError(r)
		await this.fetchSessionUser()
	},

	async submitRegisterForm(name, email, password, captchaResponse) {
		const body = new URLSearchParams({ name, email, password, 'g-recaptcha-response': captchaResponse })
		const r = await fetch('/auth/register', { method: 'POST', body })
		await processError(r)
	},

	async submitVerifyEmail(token) {
		const body = new URLSearchParams({ token })
		const r = await fetch('/auth/verify', { method: 'POST', body })
		await processError(r)
	},

	resetAuthMessages() {
		store.resetAuthMessages()
	}
}

export default auth
