<template>
	<div class="card mb-4 shadow-sm">
		<div class="card-body">
			<h4 class="card-title mb-4">{{ title }}</h4>
			<div
				:key="f.field"
				v-for="f in fields"
			>
				<FieldEditor
					:record="record"
					:field="f"
					:lang="lang"
					:desc="desc"
					v-if="edit && !f.readonly"
					@change="$emit('change', $event)"
				></FieldEditor>
				<FieldView
                                        v-else
					:record="record"
					:field="f"
					:lang="lang"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import FieldEditor from '@/components/FieldEditor'
import FieldView from '@/components/FieldView'

export default {
	props: ['title', 'record', 'fields', 'edit', 'lang', 'desc'],
	components: { FieldEditor, FieldView },
}
</script>
