<template>
	<dl>
		<dt>{{ label }}</dt>
		<div v-if="desc"> {{description}} </div>
		<dd>
			<FieldInput
				:disabled="field.readonly || disabled"
				:field="field"
				:lang="lang"
                :value="readFieldFlat(record, field.field)"
                :record="record"
				@input="onInput"
			/>
		</dd>
	</dl>
</template>

<script>
import FieldInput from '@/components/FieldInput'
import { readFieldFlat } from '@/util'

export default {
	props: ['disabled', 'record', 'field', 'lang', 'desc'],
	components: { FieldInput },
	computed: {
		label () {
			let retval = ''
			if (this.lang === 'en') {
				retval = this.field.label
			} else if (this.lang === 'hu') {
				retval = this.field.label_hu ?? this.field.label
			} else {
				retval = this.field.label
			}
			if (this.field.required) {
				retval = `${retval} *`
			}
			return retval
		},
		description () {
			if (this.lang === 'en') {
				return this.field.description_en
			} else if (this.lang === 'hu') {
				return this.field.description_hu ?? this.field.description_en
			} else {
				return 'Error'
			}
		},
	},
	methods: {
		readFieldFlat,
		onInput(value) {
			this.$emit('change', Object.assign({ value }, this.field))
		},
	},
}
</script>
