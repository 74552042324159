import store from './store'

const CACHE = {}

export default {
	async _call(endpoint, body, overrideToken, download) {
		const headers = {
			'Content-Type': 'application/json',
			id_token: overrideToken || (store.state.user ? store.state.user.id_token : null),
		}
		if (body) {
			if (body instanceof FormData) {
				delete headers['Content-Type']
			} else {
				body = JSON.stringify(body)
			}
		}
		const res = await fetch(endpoint, {
			body: body,
			headers,
			method: body ? 'POST' : 'GET'
		})
		if (download) {
			const cdh = res.headers.get('content-disposition')
			const fn = cdh.split('filename="')[1].split('"')[0]
			const blob = await res.blob()
			const file = window.URL.createObjectURL(blob)
			const a = document.createElement('a')
			a.setAttribute('href', file)
			a.setAttribute('download', fn)
			a.click()
		} else {
			let json
			try {
				json = await res.json()
			} catch (error) {
				json = {
					status: res.status,
					body: res.body
				}
			}
			return json
		}
	},

	async _cachedCall(endpoint) {
		if (CACHE[endpoint]) return CACHE[endpoint]
		const json = await this._call(endpoint)
		CACHE[endpoint] = json
		return json
	},

	animalChangeGroup(id, groupId) {
		return this._call('/api/animal/changegroup', { id, groupId })
	},

	animalEdit(body) {
		return this._call('/api/animal/edit', body)
	},

	animalFind(body) {
		return this._call('/api/animal/find', body)
	},

	animalGetId(body) {
		return this._call('/api/animal/getid', body)
	},

	animalView(id, humanize) {
		return this._call('/api/animal/view', { id, humanize })
	},

	batchGet(measurement_id, mongo_batch_id) {
		return this._call('/api/batch/get', { measurement_id, mongo_batch_id })
	},

	breedEdit(body) {
		return this._call('/api/breed/edit', body)
	},

	breedFind(body) {
		return this._call('/api/breed/find', body)
	},

	breedGetId(body) {
		return this._call('/api/breed/getid', body)
	},

	breedView(id, humanize) {
		return this._call('/api/breed/view', { id, humanize })
	},

	ownerChangeGroup(id, groupId) {
		return this._call('/api/owner/changegroup', { id, groupId })
	},

	ownerEdit(body) {
		return this._call('/api/owner/edit', body)
	},

	ownerFind(body) {
		return this._call('/api/owner/find', body)
	},

	ownerGetId(body) {
		return this._call('/api/owner/getid', body)
	},

	ownerView(id, humanize) {
		return this._call('/api/owner/view', { id, humanize })
	},

	ownerApplicationSubmit(body) {
		return this._call('/ownerapplication', body)
	},

	ownerApplicationFind(body) {
		return this._call('/api/ownerapplication/find', body)
	},

	ownerApplicationFindOne() {
		return this._call('/api/ownerapplication/findone')
	},

	ownerApplicationView(id, humanize) {
		return this._call('/api/ownerapplication/view', { id, humanize })
	},

	ownerApplicationProcess(id, ownerapplication, decision) {
		return this._call('/api/ownerapplication/process', { id, ownerapplication, decision })
	},

	experimentEdit(body) {
		return this._call('/api/experiment/edit', body)
	},

	experimentFind(body) {
		return this._call('/api/experiment/find', body)
	},

	experimentGetId(body) {
		return this._call('/api/experiment/getid', body)
	},

	experimentView(id, humanize) {
		return this._call('/api/experiment/view', { id, humanize })
	},

	tagEdit(body) {
		return this._call('/api/tag/edit', body)
	},

	tagFind(body) {
		return this._call('/api/tag/find', body)
	},

	tagGetId(body) {
		return this._call('/api/tag/getid', body)
	},

	tagView(id, humanize) {
		return this._call('/api/tag/view', { id, humanize })
	},

	authUser(overrideToken) {
		return this._call('/api/user/info', null, overrideToken)
	},

	userSelfChangeSaveTo(groupId) {
		return this._call('/api/user/settings/groups/set', { default_save_to_group: groupId })
	},

	groupAddUser(groupId, userId) {
		return this._call('/api/user/administration/users/add_user_to_group', { groupId, userId })
	},

	groupFind() {
		return this._call('/api/user/administration/groups/get')
	},

	groupRemoveUser(groupId, userId) {
		return this._call('/api/user/administration/users/remove_user_from_group', { groupId, userId })
	},

	groupToggleUserRole(groupId, userId, role) {
		return this._call('/api/user/administration/users/group_user_role_change', { groupId, userId, role })
	},

	measurementChangeGroup(id, groupId) {
		return this._call('/api/measurement/changegroup', { id, groupId })
	},

	measurementEdit(body) {
		return this._call('/api/measurement/edit', body)
	},

	measurementGetId(body) {
		return this._call('/api/measurement/getid', body)
	},

	measurementFind(body) {
		return this._call('/api/measurement/find', body)
	},

	measurementView(id, humanize) {
		return this._call('/api/measurement/view', { id, humanize })
	},

	userChangeSaveTo(userId, groupId) {
		return this._call('/api/user/administration/users/set_save_to', { userId, groupId })
	},

	userFind() {
		return this._call('/api/user/administration/users/get')
	},

	userSettings(body) {
		return this._call('/api/user/settings', body)
	},

	userToggleGlobalRole(userId, role) {
		return this._call('/api/user/administration/users/global_user_role_change', { userId, role })
	},

	deleteFile(filetype, _id, fileid) {
		return this._call('/api/db/files/delete', { filetype, _id, fileid })
	},

	downloadFile(apiName, _id) {
		return this._call(`/api/db/${apiName}/download`, { _id }, null, true)
	},

	uploadFile(apiName, measurementId, file, tag, creator_id) {
		const formData = new FormData()
		formData.append('_id', measurementId)
		formData.append('sensx_filedescription', tag)
		formData.append('creator_id', creator_id)
		formData.append('file', file)
		return this._call(`/api/db/${apiName}/upload`, formData)
	},
}
