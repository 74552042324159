import _ from 'lodash'

export function readFieldFlat(record, field) { // TODO use Lodash _.get(record, field) ?
	if (!record || !field) return record
	if (field.indexOf('.') === -1) return record[field]
	const [group, restOfField] = field.split('.', 2)
	// return readFieldFlat(record[group], restOfField)
	return _.get(record, field)
}

export function format(value, field) {
	if ((field || '').endsWith('_time')) {
		return new Date(value * 1000).toLocaleString()
	} else if (typeof value === 'boolean') {
		return value
	}
	return value || value === 0 ? value : null
}

export function getCurrentTimeString() {
	const d = new Date()
	const date = `${String(d.getFullYear())}-${String(d.getMonth()).padStart(2, 0)}-${String(d.getDate()).padStart(2, 0)}`
	const time = `${String(d.getHours()).padStart(2, 0)}:${String(d.getMinutes()).padStart(2, 0)}:${String(d.getSeconds()).padStart(2, 0)}`
	return `${date} ${time}`
}

export function startingDateValid(sd) {
	return /^\d{4}-\d\d-\d\d \d\d:\d\d:\d\d(\.\d\d\d)?/.test(sd)
}

export function formatForList(value, field) {
	if (value && value.constructor.name === 'Array') {
		return value.length + ' items'
	} else if (value && field.label_key !== undefined) {
		return value[field.label_key] ?? value
	}
	return format(value, field.field)
}

export function inputType(field) {
	if (!field) return 'text'
	if (field.type === 'number') return 'number'
	if (field.type === 'enum') return 'select'
	if (field.type === 'date') return 'datepicker'
	if (field.type === 'tags') return 'tags'
	if (field.type === 'email') return 'email'
	if (field.type === 'textarea') return 'textarea'
	if (field.type === 'multiple') return 'multiple'

	return 'text'
}

export function exportTable(selectedFields, records, filename, linkprefix) {
	const lines = []
	const header = selectedFields.map((f) => f.field)
	header.push('directlink')
	lines.push(header.join(';'))
	for (const record of records) {
		const link = `https://${window.location.hostname}/#/${linkprefix}/${record._id}`
		const line = selectedFields.map((f) => formatForList(readFieldFlat(record, f.field), f))
		line.push(link)
		lines.push(line.join(';'))
	}
	saveTextToFile(lines.join('\n'), filename)
}

export function exportJson(record, filename) {
	saveTextToFile(JSON.stringify(record, null, 4), filename)
}

export function saveTextToFile(inputtext, filename) {
	const blob = new Blob([inputtext], { type: 'text/plain' })
	const e = document.createEvent('MouseEvents')
	const a = document.createElement('a')
	a.download = filename
	a.href = window.URL.createObjectURL(blob)
	a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
	e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
	a.dispatchEvent(e)
}
