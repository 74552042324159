export const ANIMAL_DOG_FIELDS_FLAT = [
	{
		field: 'TIMEOWNER',
		label: 'Hours spent actively with owner/day',
		label_hu: 'Gazdával aktívan töltött órák száma naponta',
		type: 'number',
		required: true
	},
	{
		field: 'TIMEOTHER',
		label: 'Hours spent actively with somebody else/day',
		label_hu: 'Mással aktívan töltött órák száma naponta',
		type: 'number',
		required: true
	},
	{
		field: 'TSPWALK',
		label: 'Hours spent walking with owner/day',
		label_hu: 'Gazdával sétával töltött órák száma naponta',
		type: 'number',
		required: true
	},
	{
		field: 'TSPPLAY',
		label: 'Hours spent playing with owner/day',
		label_hu: 'Gazdával játékkal töltött órák száma naponta',
		type: 'number',
		required: true
	},
	{
		field: 'TSPTRAIN',
		label: 'Hours spent training/doing sport with owner/day',
		label_hu: 'Gazdával kiképzéssel/sportolással töltött órák száma naponta',
		type: 'number',
		required: true
	},
	{
		field: 'TSPOTHER',
		label: 'Hours spent doing other things with owner/day',
		label_hu: 'Gazdával egyéb tevékenységgel töltött órák száma naponta',
		type: 'number',
		required: true
	},
	{
		field: 'TRAINFORM',
		label: 'In what forms was the dog trained?',
		label_hu: 'Milyen módon volt képezve a kutya?',
		type: 'multiple',
		values: [
			'nothing',
			'trained by owner',
			'trained by trainer',
			'attended training classes'
		],
		values_hu: [
			'semmilyen',
			'gazda által',
			'kutyakiképző által',
			'kutyaiskolába járt'
		],
		required: true
	},
	{
		field: 'TRAINTYPE',
		label: 'If trained, what type of training did the dog get?',
		label_hu: 'Ha képezve volt, milyen típusú kiképzést kapott?',
		description: 'You can choose more options',
		description_hu: 'Többet is megjelölhet',
		type: 'multiple',
		values: [
			'obedience',
			'BH/IPO',
			'protection training',
			'agility',
			'hunting',
			'SAR',
			'service dog',
			'therapy dog',
			'herding',
			'other',
			'none'
		],
		values_hu: [
			'obedience',
			'BH/IPO',
			'őrző-védő',
			'agility',
			'vadász',
			'mentő',
			'segítő',
			'terápiás',
			'terelés',
			'egyéb',
			'semmilyen'
		],
		required: true
	},
	{
		field: 'TREXAM',
		label: 'What kind of training exams/competitions did your dog do?',
		label_hu: 'Milyen kiképzés vizsgái / versenyrészvételei vannak a kutyának?',
		description: 'You can choose more options',
		description_hu: 'Többet is megjelölhet',
		type: 'multiple',
		values: [
			'obedience',
			'BH/IPO',
			'protection training',
			'agility',
			'hunting',
			'SAR',
			'service dog',
			'therapy dog',
			'herding',
			'other',
			'none'
		],
		values_hu: [
			'obedience',
			'BH/IPO',
			'őrző-védő',
			'agility',
			'vadász',
			'mentő',
			'segítő',
			'terápiás',
			'terelés',
			'egyéb',
			'semmilyen'
		],
		required: true
	},
	{
		field: 'TRACTIVE',
		label: 'Activities the dog is currently doing',
		label_hu: 'A kutya által jelenleg űzött tevékenységek',
		description: 'You can choose more options',
		description_hu: 'Többet is megjelölhet',
		type: 'multiple',
		values: [
			'obedience',
			'BH/IPO',
			'protection training',
			'agility',
			'hunting',
			'SAR',
			'service dog',
			'therapy dog',
			'herding',
			'other',
			'none'
		],
		values_hu: [
			'obedience',
			'BH/IPO',
			'őrző-védő',
			'agility',
			'vadász',
			'mentő',
			'segítő',
			'terápiás',
			'terelés',
			'egyéb',
			'semmilyen'
		],
		required: true
	},
	{
		field: 'BEHPROBL',
		label: 'Behaviour problems of the dog',
		label_hu: 'Milyen viselkedési problémák fordulnak elő a kutyánál?',
		description: 'You can choose more options',
		description_hu: 'Többet is megjelölhet',
		type: 'multiple',
		values: [
			'none',
			'separation anxiety (crying, wrecking things when alone)',
			'aggression toward dogs',
			'aggression toward people',
			'fear',
			'jumping',
			'sensitive to noises (fireworks, thunder)',
			'extensive barking',
			'too active',
			"doesn't come back",
			'other',
		],
		values_hu: [
			'semmilyen',
			'szeparációs stressz (sír, rombol egyedül)',
			'kutyákkal agresszív',
			'emberekkel agresszív',
			'félelem',
			'felugrálás',
			'zajérzékeny (petárda, mennydörgés)',
			'sokat ugat',
			'túl aktív',
			'nem jön vissza',
			'egyéb'
		],
		required: true
	},
	{
		field: 'RELATION',
		label: 'How is your relationship with the dog',
		label_hu: 'Milyen a kapcsolata a kutyával?',
		type: 'scale',
		values: '1-7',
		description: '1 - very bad, 7 - very good',
		description_hu: '1 - nagyon rossz, 7 - nagyon jó',
		required: true
	},
	{
		field: 'Comment',
		label: 'Comment',
		label_hu: 'Megjegyzés',
		description: 'Any information of your dog may be important',
		description_hu: 'Bármi egyéb információ a kutyájával kapcsolatban, amit fontosnak gondol'
	},

]
