<template>
	<b-navbar
		class="shadow-sm"
		toggleable="lg"
		type="light"
		variant="white"
	>
		<b-navbar-brand
			class="align-items-center d-flex"
			to="/"
		>
			<b-avatar
				class="mr-2"
				src="/SensDog_icon_128.jpg"
				variant="white"
			/>
			SensX Server
		</b-navbar-brand>
		<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

		<b-collapse
			id="nav-collapse"
			is-nav
		>
			<b-navbar-nav>
				<b-nav-item to="/measurements">
					Measurements
				</b-nav-item>
				<b-nav-item to="/animals">
					Animals
				</b-nav-item>
				<b-nav-item to="/owners">
					Owners
				</b-nav-item>
				<b-nav-item to="/experiments">
					Experiments
				</b-nav-item>
				<b-nav-item to="/breeds">
					Breeds
				</b-nav-item>
				<b-nav-item to="/tags">
					Tags
				</b-nav-item>
				<b-nav-item to="/ownerapplication">
					Application form preview
				</b-nav-item>
			</b-navbar-nav>
			<b-navbar-nav class="ml-auto">
				<b-nav-item to="/admin" v-if="globalRoles.includes('admin')">
					Admin
				</b-nav-item>
				<b-nav-item to="/ownerapplications" v-if="globalRoles.includes('applicationmanager')">
					Applications
				</b-nav-item>
				<b-nav-item to="/apidocs">
					API Docs
				</b-nav-item>
				<b-nav-item-dropdown right>
					<template v-slot:button-content>
						References
					</template>
					<b-dropdown-item
						:href="r.url"
						:key="r.url"
						target="_blank"
						v-for="r in references"
					>{{ r.text }}</b-dropdown-item>
				</b-nav-item-dropdown>
				<b-nav-item-dropdown
					class="ml-2"
					right
					v-if="sharedState.user"
				>
					<template v-slot:button-content>
						<b-avatar :src="sharedState.user.imageUrl"></b-avatar>
					</template>
					<b-dropdown-text>
						<strong>{{ sharedState.user.name }}</strong>
						<br>
						<small>{{ sharedState.user.email }}</small>
					</b-dropdown-text>
					<b-dropdown-divider />
					<b-dropdown-item @click="logout()">Log out</b-dropdown-item>
				</b-nav-item-dropdown>
			</b-navbar-nav>
		</b-collapse>
	</b-navbar>
</template>

<script>
import auth from '@/auth'

export default {
	data() {
		return {
			references: [
				{
					text: 'User Guide and Data Management Guide',
					url:
						'https://nc.elte.hu/f/3961236',
				},
				{
					text: 'Unix timestamp converter',
					url: 'http://www.unixtimestamp.com/',
				},
				{
					text: 'Regex cheat sheet',
					url: 'http://www.rexegg.com/regex-quickstart.html',
				},
			],
		}
	},
	computed: {
		globalRoles() {
			try {
				return this.sharedState.user.info.roles || []
			} catch {
				return []
			}
		},
	},
	methods: {
		logout() {
			auth.logout()
		},
	},
}
</script>
