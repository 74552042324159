<template>
	<div class="container">
		<b-overlay
			:show="loading"
			rounded="sm"
		>
			<h1 class="border-bottom d-flex mb-5">
				<span class="mr-auto">{{ header }}</span>
				<button
					class="btn btn-link"
					@click="lang = 'hu'"
				>magyar
				</button>
				<button
					class="btn btn-link"
					@click="lang = 'en'"
				>English
				</button>
			</h1>
			<div style="margin:auto">
				<div class="col-md-6">
					<FieldGroupCard
						:edit="edit"
						:lang="lang"
						:desc="true"
						:fields="APPLICATION_OWNER_FIELDS_FLAT"
						:record="record_owner"
						:title="ownerheader"
						@change="onChangeOwner"
					/>
				</div>
				<div class="col-md-6">
					<FieldGroupCard
						:edit="edit"
						:lang="lang"
						:desc="true"
						:fields="APPLICATION_ANIMAL_FIELDS_FLAT"
						:record="record_animal"
						:title="animalheader"
						@change="onChangeAnimal"
					/>
				</div>
				<div class="col-md-6">
					<FieldGroupCard
						:edit="edit"
						:lang="lang"
						:desc="true"
						:fields="APPLICATION_ANIMAL_DOG_FIELDS_FLAT"
						:record="record_animal"
						:title="typeSpecificHeader"
						@change="onChangeAnimal"
					/>
				</div>
				<div v-for="f in APPLICATION_OWNER_FIELDS_FLAT" :key="f.field">
					<b-alert show variant="danger"
					v-if="!record_owner[f.field] && f.required && triedToSubmit">
						{{lang == 'en' ? `Please fill required field: ${f.label}` : `Kérjük töltse ki a kötelező mezőt: ${f.label_hu}` }}
					</b-alert>
				</div>
				<div class="col-md-6">
					<div class="card mb-4 shadow-sm">
						<div class="card-body">
							<center>
							<button
								class="btn btn-link"
								@click="submit"
							>
								{{lang == 'en' ? 'Submit' : 'Beadás'}}
								<b-icon icon="arrow-right-circle"></b-icon>
							</button>
							</center>
						</div>
					</div>
				</div>
			</div>
		</b-overlay>
	</div>
</template>

<script>
/* import apiClient from '@/apiClient' */
import FieldGroupCard from '@/components/FieldGroupCard'
import {
	APPLICATION_OWNER_FIELDS_FLAT,
	APPLICATION_ANIMAL_FIELDS_FLAT,
	APPLICATION_ANIMAL_DOG_FIELDS_FLAT,
	APPLICATION_ANIMAL_CAT_FIELDS_FLAT,
	APPLICATION_ANIMAL_FULL_FIELDS_FLAT,
} from '@/fields/ownerapplication_fields'
import { readFieldFlat } from '@/util'
import apiClient from '../apiClient'
const APPLICATION_OWNER_FIELDS_FLAT_LOC = APPLICATION_OWNER_FIELDS_FLAT.filter(val => !['preferredLang'].includes(val.field))

export default {
	components: { FieldGroupCard },
	data() {
		return {
			APPLICATION_OWNER_FIELDS_FLAT: APPLICATION_OWNER_FIELDS_FLAT_LOC,
			APPLICATION_ANIMAL_FIELDS_FLAT,
			APPLICATION_ANIMAL_DOG_FIELDS_FLAT,
			APPLICATION_ANIMAL_CAT_FIELDS_FLAT,
			APPLICATION_ANIMAL_FULL_FIELDS_FLAT,
			changeset: {},
			edit: true,
			loading: false,
			record_owner: {},
			record_animal: {},
			lang: 'hu',
			triedToSubmit: false
		}
	},
	computed: {
		header() {
			if (this.lang === 'en') {
				return 'ELTE Department of Ethology - Owner Application Form'
			} else if (this.lang === 'hu') {
				return 'ELTE Etológia Tanszék - Gazda Jelentkezési Lap'
			} else {
				return 'Error'
			}
		},
		ownerheader() {
			if (this.lang === 'en') {
				return 'Owner details'
			} else if (this.lang === 'hu') {
				return 'Gazda adatok'
			} else {
				return 'Error'
			}
		},
		animalheader() {
			if (this.lang === 'en') {
				return 'Animal details'
			} else if (this.lang === 'hu') {
				return 'Állat adatok'
			} else {
				return 'Error'
			}
		},
		typeSpecificHeader() {
			if (this.lang === 'en') {
				return `${this.record_animal.animalType} specific information`
			} else if (this.lang === 'hu') {
				return `${this.record_animal.animalType} specifikus információk`
			} else {
				return 'Error'
			}
		}
	},
	methods: {
		readFieldFlat,
		translateFields (target) {
			this.APPLICATION_ANIMAL_FIELDS_FLAT.forEach(
				f => {
					if (f.type === 'enum' && Array.isArray(f.values)) {
						if (target === 'en' && Array.isArray(f.values_hu)) {
							const index = f.values_hu.indexOf(this.record_animal[f.field])
							if (index > -1) {
								this.record_animal[f.field] = f.values[index]
							}
						} else {
							const index = f.values.indexOf(this.record_animal[f.field])
							if (index > -1) {
								this.record_animal[f.field] = f.values_hu[index]
							}
						}
					}
				}
			)
			this.APPLICATION_OWNER_FIELDS_FLAT.forEach(
				f => {
					if (f.type === 'enum' && Array.isArray(f.values)) {
						if (target === 'en' && Array.isArray(f.values_hu)) {
							const index = f.values_hu.indexOf(this.record_owner[f.field])
							if (index > -1) {
								this.record_owner[f.field] = f.values[index]
							}
						} else {
							const index = f.values.indexOf(this.record_owner[f.field])
							if (index > -1) {
								this.record_owner[f.field] = f.values_hu[index]
							}
						}
					}
				}
			)
		},
		onChangeAnimal(fieldWithValue) {
			const f = fieldWithValue.field
			const newValue = fieldWithValue.value
			this.$set(this.record_animal, f, newValue)
		},
		onChangeOwner(fieldWithValue) {
			const f = fieldWithValue.field
			const newValue = fieldWithValue.value
			this.$set(this.record_owner, f, newValue)
		},
		checkRequired() {
			var badfields = []
			this.APPLICATION_OWNER_FIELDS_FLAT.forEach(
				f => { if (!this.record_owner[f.field] && f.required) { badfields.push(f.field) } }
			)
			this.APPLICATION_ANIMAL_FULL_FIELDS_FLAT.forEach(
				f => { if (!this.record_animal[f.field] && f.required) { badfields.push(f.field) } }
			)
			return badfields
		},
		async submit() {
			const badfields = this.checkRequired()
			if (badfields.length === 0) {
				this.record_owner.preferredLang = this.lang
				const req = {
					animal: this.record_animal,
					owner: this.record_owner
				}
				console.log(await apiClient.ownerApplicationSubmit(req))
			} else {
				this.triedToSubmit = true
				console.log(badfields)
			}
		}
	},
	watch: {
		lang(l) {
			this.translateFields(l)
		}
	},
	mounted() {
		if (window.navigator.language === 'hu-HU') {
			this.lang = 'hu'
		} else {
			this.lang = 'en'
		}
		this.APPLICATION_OWNER_FIELDS_FLAT.forEach(
			f => { this.$set(this.record_owner, f.field, null) }
		)
		this.APPLICATION_ANIMAL_FULL_FIELDS_FLAT.forEach(
			f => {
				if (f.type === 'multiple') {
					this.$set(this.record_animal, f.field, [])
				} else {
					this.$set(this.record_animal, f.field, null)
				}
			}
		)
		this.$set(this.record_animal, 'animalType', 'kutya')
	},
}
</script>
