import Vue from 'vue'
import { AvatarPlugin, CollapsePlugin, DropdownPlugin, FormDatepickerPlugin, FormFilePlugin, IconsPlugin, InputGroupPlugin, ModalPlugin, NavbarPlugin, OverlayPlugin, TabsPlugin, ToastPlugin, TooltipPlugin, AlertPlugin } from 'bootstrap-vue'
import App from './App.vue'
import OwnerApplication from './views/OwnerApplication.vue'
import router from './router'
import store from './store'
import './scss/custom.scss'
import VoerroTagsInput from '@voerro/vue-tagsinput'
import '@voerro/vue-tagsinput/dist/style.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

Vue.component('tags-input', VoerroTagsInput)
Vue.component('v-select', vSelect)

Vue.config.productionTip = false

Vue.use(AvatarPlugin)
Vue.use(CollapsePlugin)
Vue.use(DropdownPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormFilePlugin)
Vue.use(IconsPlugin)
Vue.use(InputGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(NavbarPlugin)
Vue.use(OverlayPlugin)
Vue.use(TabsPlugin)
Vue.use(ToastPlugin)
Vue.use(TooltipPlugin)
Vue.use(AlertPlugin)
Vue.mixin({
	data() {
		return {
			sharedState: store.state
		}
	}
})
if (['etojelentkezes', 'etojelentkezes-dev'].some(v => window.location.hostname.includes(v))) {
	new Vue({
		router,
		render: h => h(OwnerApplication)
	}).$mount('#app')
} else {
	new Vue({
		router,
		render: h => h(App)
	}).$mount('#app')
}
