<template>
	<dl>
		<dt>{{ field.label }}</dt>
		<dd v-if="value && value.constructor.name === 'Array'">
			<ul
				class="pl-4"
				v-if="value.length"
			>
				<li
					class="mb-2"
					:key="i"
					v-for="(e, i) in value"
				>
					<span v-if="field.field === 'audit.trail'">
						{{ new Date(e[1] * 1000).toLocaleString() }}
						-
						{{ e[0] }}
						<br>
						{{ e[2] }}
                    </span>
                    <span v-else-if="field.field === 'tags'">
			<router-link :to="'/tag/' + e._id">
                        {{e[field.label_key]}}
                        -
                        {{e._id}}
			</router-link>
                    </span>
                    <span v-else-if="field.label_key !== undefined">
                        {{e[field.label_key]}}
                        -
                        {{e._id}}
                    </span>
					<span v-else>{{ e }}</span>
				</li>
			</ul>
			<span v-else>-</span>
		</dd>
		<dd v-else-if="field.type === 'textarea'">
			<vue-marked> {{value}} </vue-marked>
		</dd>

		<dd v-else-if="field.field === 'animalId'">
			<router-link v-if="value._id" :to="'/animal/' + value._id">{{
			value.animalName }} ({{value._id}})</router-link>
			<span v-else> - </span>
		<dd v-else-if="field.field === 'animalBreed'">
			<router-link v-if="value._id" :to="'/breed/' + value._id">{{
			value.name_en }} ({{value._id}})</router-link>
			<span v-else> - </span>
		<dd v-else-if="field.field === 'ownerId'">
			<router-link v-if="value._id" :to="'/owner/' + value._id">{{
			value.fullName }} ({{value._id}})</router-link>
			<span v-else> - </span>
		<dd v-else-if="field.field === 'experiment_id'">
			<router-link v-if="value._id" :to="'/experiment/' + value._id">{{
			value.short_name }} ({{value._id}})</router-link>
			<span v-else> - </span>
		<dd v-else-if="field.field === 'animalexists'">
			<router-link v-if="value" :to="'/animal/' + value">
				yes
			</router-link>
			<span v-else> no </span>
		<dd v-else-if="field.field === 'ownerexists'">
			<router-link v-if="value" :to="'/owner/' + value">
				yes
			</router-link>
			<span v-else> no </span>
		<dd v-else-if="field.type === 'link'">
			<a :href="value" target="_blank">{{ value }}</a>
		<dd v-else-if="field.type === 'email'">
			<a :href="`mailto:${value}`" >{{ value }}</a>
        </dd>
        <dd v-else-if="field.label_key !== undefined">
            {{value ? value[field.label_key] + ' (' + value._id + ')' : '-' }}
        </dd>
		<dd v-else>{{ value.toString() || '-' }}</dd>
	</dl>
</template>

<script>
import { format, readFieldFlat } from '@/util'
import VueMarked from 'vue-marked'

export default {
	props: ['record', 'field'], // TODO we should rework this component to use field.type and stuff
	components: { VueMarked },
	computed: {
		value() {
			return format(
				readFieldFlat(this.record, this.field.field),
				this.field.field
			)
		},
	},
}
</script>
