import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

Vue.use(VueRouter)

function r(path, name, requiresAuth) {
	return {
		path,
		name,
		meta: { requiresAuth },
		component: () => import('./views/' + name + '.vue')
	}
}

const routes = [
	// public
	r('/changepw/:token', 'ChangePw'),
	r('/forgot', 'ForgotPw'),
	r('/login', 'Login'),
	r('/register', 'Register'),
	r('/verify/:token', 'VerifyEmail'),
	r('/ownerapplication', 'OwnerApplication'),

	// authenticated
	r('/', 'Home', true),
	r('/admin', 'Admin', true),
	r('/apidocs', 'ApiDocs', true),
	r('/measurements', 'Measurements', true),
	r('/measurement/:id', 'Measurement', true),
	r('/animals', 'Animals', true),
	r('/animal/:id', 'Animal', true),
	r('/breeds', 'Breeds', true),
	r('/breed/:id', 'Breed', true),
	r('/batch/:measurement_id/:mongo_batch_id', 'Batch', true),
	r('/owners', 'Owners', true),
	r('/owner/:id', 'Owner', true),
	r('/experiments', 'Experiments', true),
	r('/experiment/:id', 'Experiment', true),
	r('/tags', 'Tags', true),
	r('/tag/:id', 'Tag', true),
	r('/ownerapplications', 'OwnerApplications', true),
	r('/ownerapplicationadmin/:id', 'OwnerApplicationAdmin', true),
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
	// TODO we should detect Login/Reg pages as they require NON-auth state - though Login and Unverified pages have to be splitted
	// TODO should ensure admin role to admin route

	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	const authenticated = store.state.user
	const isjelentkezes = ['etojelentkezes', 'etojelentkezes-dev'].some(v => window.location.hostname.includes(v))

	if (requiresAuth && !authenticated && !isjelentkezes) {
		next({ name: 'Login', query: { redirect: to.fullPath } })
	} else {
		next()
	}
})

export default router
