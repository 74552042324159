import router from './router'

const store = {
	state: {
		authError: null,
		authSuccess: null,
		googleAuth: null,
		loading: false,
		user: null,
	},
	resetAuthMessages() {
		this.state.authError = null
		this.state.authSuccess = null
	},
	setGoogleAuth(googleAuth) {
		this.state.googleAuth = googleAuth
	},
	signIn(name, givenName, email, imageUrl, id_token, expires_in, verified, info) {
		this.state.user = { name, givenName, email, imageUrl, id_token, expires_in, verified, info }
		if (router.currentRoute.query.redirect) {
			router.push(router.currentRoute.query.redirect)
		}
	},
	signOut() {
		this.state.user = null
		router.push('/login').catch(() => { })
	},
	updateUserInfo(userinfo) {
		this.state.user.info = userinfo
	}
}
window.store = store
export default store
