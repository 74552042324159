import { AUDIT_UNMODIFIABLE_FLAT } from './audit_fields'
import { OWNER_FIELDS_FLAT } from './owner_fields'
import { ANIMAL_FIELDS_FLAT, ANIMAL_DOG_FIELDS_FLAT, ANIMAL_FULL_FIELDS_FLAT, ANIMAL_CAT_FIELDS_FLAT } from './animal_fields'

export const APPLICATION_OWNER_FIELDS_FLAT = [
	...OWNER_FIELDS_FLAT.filter(
		val => !['_id', 'external_resource_link', 'tags'].includes(val.field)
	).map(f => Object.assign({}, f, { field: `${f.field}` }))
]

export const APPLICATION_ANIMAL_FULL_FIELDS_FLAT = [
	...ANIMAL_FIELDS_FLAT.filter(
		val => !['_id', 'external_resource_link', 'tags', 'ownerId'].includes(val.field)
	).map(f => Object.assign({}, f, { field: `${f.field}` })),
]

export const APPLICATION_ANIMAL_DOG_FIELDS_FLAT = ANIMAL_DOG_FIELDS_FLAT
export const APPLICATION_ANIMAL_CAT_FIELDS_FLAT = ANIMAL_CAT_FIELDS_FLAT

export const APPLICATION_EXTRA_FIELDS = [
	{
		field: 'animalexists',
		label: 'Animal exists',
		readonly: true
	},
	{
		field: 'ownerexists',
		label: 'Owner exists',
		readonly: true
	}
]

export const APPLICATION_FULL_FIELDS_FLAT = [
	...APPLICATION_OWNER_FIELDS_FLAT.map(f => Object.assign({}, f, { field: `owner.${f.field}`, label: `O: ${f.label}` })),
	...APPLICATION_ANIMAL_FULL_FIELDS_FLAT.map(f => Object.assign({}, f, { field: `animal.${f.field}`, label: `A: ${f.label}` })),
	...APPLICATION_EXTRA_FIELDS,
	...AUDIT_UNMODIFIABLE_FLAT
]
