export const AUDIT_UNMODIFIABLE_FLAT = [
	{
		field: 'audit.created_by',
		label: 'Created by',
		nofilter: false,
		readonly: true,
		type: 'enum',
		values: '/listofusernames',
		label_key: 'name'
	},
	{
		field: 'audit.creation_time',
		label: 'Created at',
		nofilter: true, // TODO epoch as double as string
		readonly: true
	},
]

export const AUDIT_GROUPLESS_FIELDS_FLAT = [
	...AUDIT_UNMODIFIABLE_FLAT,
	{
		field: 'audit.last_modification_time',
		label: 'Last modified at',
		nofilter: true, // TODO epoch as double as string
		readonly: true
	},
	{
		field: 'audit.last_modified_by',
		label: 'Last modified by',
		nofilter: false,
		readonly: true,
		type: 'enum',
		values: '/listofusernames',
		label_key: 'name'
	},
	{
		field: 'audit.owner',
		label: 'Record owner',
		nofilter: false,
		readonly: false,
		type: 'enum',
		values: '/listofusernames',
		label_key: 'name'
	},
	{
		field: 'audit.trail',
		label: 'Modifications (audit trail)',
		nofilter: true, // TODO array, needs filter for length ($size)
		readonly: true
	},
]

export const AUDIT_FIELDS_FLAT = [
	...AUDIT_GROUPLESS_FIELDS_FLAT,
	{
		field: 'audit.group',
		label: 'Group',
		type: 'enum',
		values: '/listofgroups',
		nofilter: false,
		label_key: 'name'
	}
]
