<template>
	<div
		class="bg-light d-flex flex-column"
		id="app"
	>
		<div id="main">
			<NavBar v-if="sharedState.user" />
			<div class="container-fluid py-5">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
import NavBar from '@/components/NavBar'

export default {
	components: { NavBar },
}
</script>
