export const ANIMAL_CAT_FIELDS_FLAT = [
	{
		field: 'MOSTTIMEWHERE',
		label: 'Where does your cat spend the most time?',
		label_hu: 'Hol tölti a legtöbb időt a macskája?',
		type: 'enum',
		values: [
			'inside',
			'outside (garden, balcony)',
			'outside, freely moving in the neighbourhood',
		],
		values_hu: [
			'benn',
			'kinn (kertben, erkélyen)',
			'kinn, szabadon mozog a környéken',
		],
		required: true
	},
	{
		field: 'TAKESOMEWHERE',
		label: 'Do you take the cat outside of your home?',
		label_hu: 'El szokta hagyni az otthonát a macskájával?',
		type: 'enum',
		values: [
			'never',
			'yes, but only to the vet',
			'rarely, but not only to the vet',
			'approx. monthly',
			'approx. weekly',
			'more times a week'
		],
		values_hu: [
			'nem, soha (az állatorvos is házhoz jön)',
			'csak állatorvoshoz',
			'ritkán, de nem csak állatorvoshoz',
			'kb. havonta',
			'kb. hetente',
			'hetente többször is',
		],
		required: true
	},
	{
		field: 'MEETOTHERPEOPLE',
		label: 'Does the cat meet with other, strange people?',
		label_hu: 'Szokott a macskája idegen emberekkel találkozni?',
		type: 'enum',
		values: [
			'never',
			'yes, occasionally',
			'yes, often',
		],
		values_hu: [
			'nem, soha',
			'igen, néha',
			'igen, gyakran',
		],
		required: true
	},
	{
		field: 'BEHAVOTHERPEOPLE',
		label: 'How does the cat behave in the presence of srangers?',
		label_hu: 'Hogyan viselkedik a macska idegen emberek jelenlétében?',
		type: 'enum',
		values: [
			'friendly, approaching them',
			'curious but shy',
			'depends on the stranger',
			'fearful, hiding',
			'does not meet with strange people',
			'other'
		],
		values_hu: [
			'barátságos, bátran odamegy hozzájuk',
			'kíváncsi, érdeklődő, de félénk',
			'attól függ, mennyire szimpatikusak neki',
			'fél tőlük, elbújik',
			'nem találkozik idegenekkel',
			'egyéb',
		],
		required: true
	},
	{
		field: 'FEARFUL',
		label: 'How shy, fearful is the cat?',
		label_hu: 'Mennyire aggódó, ideges, félénk típusú a macska?',
		type: 'scale',
		values: ['1-7'],
		description: '1 - Very shy and fearful, do not get used to new situations easily, 7 - Brave and confident even at new places and with new people',
		description_hu: '1 -Nagyon félénk, ijedős, nehezen szokik hozzá új helyzetekhez ,7 - Bátor, nagyon magabiztos új helyen, új emberekkel is',
		required: true
	},
	{
		field: 'CONTROLLABLE',
		label: 'How much you can control the cat?',
		label_hu: 'Mennyire kontrollálható a macska?',
		type: 'scale',
		values: ['1-7'],
		description: '1 - I cannot control the cat et all, 7 - It is easy to control the cat, it is adaptive',
		description_hu: '1 -Egyáltalán nem kontrollálható, 7 - Alkalmazkodó, így könnyedén kontrollálható',
		required: true
	},
	{
		field: 'TRAINING',
		label: 'How you tried to train the cat?',
		label_hu: 'Próbálta tréningezni a macskát?',
		type: 'enum',
		values: [
			'no, they cannot be trained',
			'no, I like cats for their natural behaviour',
			'yes, but only for the most important things (e.g. do not bite, do not go on the kitchen table)',
			'yes, I tried to teach the cat several tricks and rules',
			'other'
		],
		values_hu: [
			'szinte egyáltalán nem, úgysem lehet',
			'szinte egyáltalán nem, a macskát ilyennek szeretem',
			'csak a legfontosabbakat (pl. ne harapjon, ne menjen fel a konyhaasztalra)',
			'trükköket, feladatokat is próbáltam tanítani neki',
			'egyéb'
		],
		required: true
	},
	{
		field: 'TRAININGSUCCESS',
		label: 'How much you succeed with the training?',
		label_hu: 'Mennyire volt sikeres a tréning?',
		type: 'enum',
		values: [
			"I haven't tried",
			'I tried but did not succeed et all',
			'I succeed in training the cat not to cause pain during playing',
			'I succeed in training the cat not to go on the kitchen table when we can see it',
			'I succeed in training the cat not to go on the kitchen table even when we cannot see it',
			'I succeed in training the cat to do some tricks for treats',
			'I succeed in training the cat to come when called, and to go away when asked, even without treats',
			'other'
		],
		values_hu: [
			'nem próbáltam',
			'szinte egyáltalán nem tudtam megtanítani semmire',
			'elértem, hogy nem okoz fájdalmat játékból',
			'elértem, hogy nem megy fel a konyhaasztalra (ha látjuk)',
			'elértem, hogy nem megy fel a konyhaasztalra (akkor sem, ha nem látjuk)',
			'jutalomfalatért kisebb feladatokat is megcsinál',
			'odajön, ha hívom (jutalom nélkül is), és kimegy, ha küldöm',
			'egyéb'
		],
		required: true
	},
	{
		field: 'BEHAVPROBLEM',
		label: 'What kind of behaviour problems does the cat have?',
		label_hu: 'Milyen viselkedésproblémák fordulnak elő a macskánál?',
		description: 'You can choose more options',
		description_hu: 'Többet is megjelölhet',
		type: 'multiple',
		values: [
			'nothing',
			'scratching furnitures',
			'extensive meowing with no obvious reason',
			'sometimes peeing/pooing inside (or onto furnitures) instead of being housetrained',
			'begging for letting inside/outside continiously',
			'jumping on everything (and push off things)',
			'kneading on soft materials',
			'stealing food',
			'other',
		],
		values_hu: [
			'semmilyen',
			'összekarmolja a bútorokat',
			'nyilvánvaló ok nélkül hosszasan nyávog',
			'időnként indokolatlanul bepiszkít (levizel tárgyakat), pedig szobatiszta',
			'örökké ki akar menni vagy be akar jönni',
			'felugrik mindenhova (lever tárgyakat)',
			'dagaszt puha anyagokon',
			'ételt lop',
			'egyéb',
		],
		required: true
	},
]
