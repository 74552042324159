<template>
	<div>
		<v-select
			v-if="type == 'select'"
			v-model="innerValue"
			:options="options"
			:label="label"
		/>
		<v-select
			v-else-if="type == 'multiple'"
			v-model="innerValue"
			:options="options"
			:label="label"
			multiple
		/>
		<b-input-group v-else-if="type == 'datepicker'">
			<input
				autocomplete="off"
				class="form-control"
				placeholder="YYYY-MM-DD"
				type="text"
				v-model="innerValue"
			>
			<b-input-group-append>
				<b-form-datepicker
					button-only
					button-variant="info"
					right
					locale="en-US"
					v-model="innerValue"
				></b-form-datepicker>
			</b-input-group-append>
		</b-input-group>
		<tags-input
			v-else-if="type == 'tags'"
			element-id="tags"
			v-model="innerValue"
			:existing-tags="options"
			id-field="_id"
			text-field="name"
			typeahead
			typeahead-style="badges"
			only-existing-tags
			typeahead-show-on-focus
			typeahead-always-show
			typeahead-hide-discard
		>hellobello
		</tags-input>
		<textarea
			v-else-if="type == 'textarea'"
			class="form-control"
			v-model="innerValue"
			style="width:100%"
		>

		</textarea>
		<input
			class="form-control"
			:disabled="disabled"
			placeholder="-"
			:type="type"
			v-else
			v-model="innerValue"
		>
	</div>
</template>

<script>
import apiClient from '@/apiClient'
import { inputType } from '@/util'
import { get as _get } from 'lodash'

export default {
	props: ['field', 'value', 'disabled', 'record', 'lang'],
	data() {
		return {
			innerValue: this.value,
			options: []
		}
	},
	computed: {
		type() {
			return inputType(this.field)
		},
		label() {
			if (this.lang === 'hu') {
				return this.field.label_key_hu ?? (this.field.label_key ?? 'label')
			} else {
				return this.field.label_key ?? 'label'
			}
		}
	},
	methods: {
		async refreshOptions() {
			/* for enums, we either expect an object with key value */
			/* pairs, OR a list of objects, the key value pairs will
			 * be converted to list of objects
			 */
			const f = this.field
			if (!f) return
			this.options.splice(0, this.options.length)
			if (f.type === 'enum' || f.type === 'tags' || f.type === 'multiple') {
				if (Array.isArray(f.values)) {
					if (this.lang === 'hu' && Array.isArray(f.values_hu)) {
						this.options.push(...f.values_hu)
					} else {
						this.options.push(...f.values)
					}
				} else {
					var callstring = f.values.split(':')[0]
					for (var i = 1; i < f.values.split(':').length; i++) {
						callstring += ':' + _get(this.record, f.values.split(':')[i])
					}
					let values = await apiClient._cachedCall(callstring)
					if (!Array.isArray(values)) {
						values = Object.entries(
							values
						).map(([_id, label]) => {
							var retval = {}
							retval._id = _id
							retval[this.label] = `${label} (${_id})`
							return retval
						})
					}
					// TODO: sorting
					this.options.push(...values)
				}
			}
		}
	},
	watch: {
		field() {
			this.refreshOptions()
		},
		value(v) {
			// modified from outside
			this.innerValue = v
		},
		innerValue(v) {
			if (this.type === 'number') {
				v = Number(v)
			}
			this.$emit('input', v)
		},
		lang() {
			this.refreshOptions()
		}
	},
	mounted() {
		this.refreshOptions()
	},
}
</script>
<style>
input:invalid {
  border: 2px dashed red;
}
</style>
