import { AUDIT_FIELDS_FLAT } from './audit_fields'
import { ANIMAL_DOG_FIELDS_FLAT } from './animal_dog_fields'
import { ANIMAL_CAT_FIELDS_FLAT } from './animal_cat_fields'
import { OWNER_FIELDS_FLAT } from './owner_fields'

export const ANIMAL_FIELDS_FLAT = [
	{
		field: '_id',
		label: 'ID',
		nofilter: true, // TODO ObjectId, filter must be augmented on server
		readonly: true
	},
	{
		field: 'animalName',
		label: 'Calling name of the animal',
		label_hu: 'Az állat hívóneve',
		type: 'string',
		required: true

	},
	{
		field: 'ownerId',
		label: 'Owner ID',
		label_hu: 'Gazda ID',
		type: 'enum',
		values: '/api/owner/list',
		label_key: 'label',
		required: true
	},
	{
		field: 'animalType',
		label: 'Type',
		label_hu: 'Faj',
		type: 'enum',
		values: [
			'cat',
			'dog',
			'horse',
			'human',
			'not_animal',
			'other',
			'pig',
			'wolf',
			'cattle'
		],
		values_hu: [
			'macska',
			'kutya',
			'ló',
			'ember',
			'nem_állat',
			'egyéb',
			'törpemalac',
			'farkas',
			'szarvasmarha'
		],
		required: true
	},
	{
		field: 'animalBreed',
		label: 'Breed',
		label_hu: 'Fajta',
		type: 'enum',
		values: '/breedlist:animalType',
		label_key: 'name_en',
		label_key_hu: 'name_hu',
		required: true
	},
	{
		field: 'animalChipID',
		label: 'Chip ID',
		label_hu: 'Mikrochipszám',
		type: 'number',
		required: true
	},
	{
		field: 'animalBirthDate',
		label: 'Birth date',
		label_hu: 'Születési dátum',
		description: 'If you do not know the exact birthdate, please give an approximate date',
		description_hu: 'Amennyiben nem tudja a pontos születési dátumát, kérem egy körülbelüli dátumot adjon meg',
		type: 'date',
		required: true
	},
	{
		field: 'PEDNAME',
		label: 'Pedigree name',
		label_hu: 'Törzskönyvi név',
		type: 'string',
		required: false
	},
	{
		field: 'PEDNO',
		label: 'Pedigree number',
		label_hu: 'Törzskönyvi szám',
		type: 'string',
		required: false
	},
	{
		field: 'animalAcqDate',
		label: 'Acquisition date',
		label_hu: 'Állat beszerzésének dátuma',
		description: 'If you do not know the exact date of acquisition, please give an approximate date',
		description_hu: 'Amennyiben nem tudja a pontos dátumot, mikor Önhöz került az állat, kérem egy körülbelüli dátumot adjon meg',
		type: 'date',
		required: true
	},
	{
		field: 'animalAcqSource',
		label: 'Source of acquisition',
		label_hu: 'Honnan került Önhöz az állat?',
		type: 'enum',
		values: [
			'born at my place',
			'from shelter',
			'found on the street',
			'from breeder',
			'from non-official breeder',
			'from a friend/acquaintance',
			'other',
		],
		values_hu: [
			'nálam született',
			'menhelyről',
			'utcáról',
			'tenyésztőtől',
			'nem bejegyzett tenyésztőtől',
			'baráttól/ismerőstől',
			'egyéb',
		],
		required: true
	},
	{
		field: 'animalHairLength',
		label: 'Hair length',
		label_hu: 'Szőrhossz',
		type: 'enum',
		values: [
			'short',
			'medium',
			'long',
			'unknown'
		],
		values_hu: [
			'rövid',
			'közepes',
			'hosszú',
			'ismeretlen'
		],
		required: true
	},
	{
		field: 'animalNeutered',
		label: 'Neutered',
		label_hu: 'Ivari státusz',
		type: 'enum',
		values: [
			'neutered',
			'not_neutered',
			'unknown'
		],
		values_hu: [
			'ivartalanított',
			'nem ivartalanított',
			'nem tudom'
		],
		required: true
	},
	{
		field: 'animalNeuterWhen',
		label: 'What age was the animal neutered/spayed at?',
		label_hu: 'Milyen idős korban ivartalanították?',
		type: 'enum',
		values: [
			'<6 month',
			'6-14 month',
			'14 month - 8 year',
			'>8 year',
			'the animal is not neutered'
		],
		values_hu: [
			'< 6 hónapos',
			'6-14 hónapos',
			'14 hónapos-8 éves',
			'>8 éves',
			'nincs ivartalanítva'
		],
		required: true
	},
	{
		field: 'animalNeuterWhy',
		label: 'What was the reason for neutering?',
		label_hu: 'Mi volt az oka az ivartalanításnak?',
		type: 'multiple',
		values: [
			'due to medical reasons',
			'to prevent reproduction',
			'due to behaviour problems (agression, escaping)',
			'the animal was neutered at the shelter/it was a requirement of the shelter',
			'other',
			'the animal is not neutered'
		],
		values_hu: [
			'egészségügyi okokból',
			'nem kívánt szaporulat miatt',
			'viselkedési okokból (agresszió, elszökés)',
			'menhelyen ivartalanították/az örökbefogadás feltétele volt',
			'egyéb',
			'nincs ivartalanítva'
		],
		required: true
	},
	{
		field: 'animalSex',
		label: 'Sex',
		label_hu: 'Nem',
		type: 'enum',
		values: [
			'female',
			'male',
			'unknown'
		],
		values_hu: [
			'nőstény',
			'hím',
			'nem tudom'
		],
		required: true
	},
	{
		field: 'animalWeight',
		label: 'Weight (kg)',
		label_hu: 'Testtömeg (kg)',
		type: 'number',
		required: true
	},
	{
		field: 'animalHeight',
		label: 'Wither height(cm)',
		label_hu: 'Marmagasság (cm)',
		type: 'number',
		required: true
	},
	{
		field: 'food_motivation',
		label: 'Food motivation?',
		label_hu: 'Motiválható étellel?',
		type: 'enum',
		values: [
			'yes',
			'no',
		],
		values_hu: [
			'igen',
			'nem',
		],
		required: true
	},
	{
		field: 'toy_motivation',
		label: 'Toy motivation?',
		label_hu: 'Motiválható játékkal?',
		type: 'enum',
		values: [
			'yes',
			'no',
		],
		values_hu: [
			'igen',
			'nem',
		],
		required: true
	},
	{
		field: 'LiveHouse',
		label: 'Hours spend in the house/day (average)',
		label_hu: 'Házban töltött órák száma naponta (átlag)',
		type: 'number',
		required: true
	},
	{
		field: 'LiveGarden',
		label: 'Hours spend in the garden/day (average)',
		label_hu: 'Kertben töltött órák száma naponta (átlag)',
		type: 'number',
		required: true
	},
	{
		field: 'LiveKennel',
		label: 'Hours spend in the kennel/day (average)',
		label_hu: 'Kennelben töltött órák száma naponta (átlag)',
		type: 'number',
		required: true
	},
	{
		field: 'LiveOther',
		label: 'Hours spend in other places/day (average)',
		label_hu: 'Máshol töltött órák száma naponta (átlag)',
		type: 'number',
		required: true
	},
	{
		field: 'Role',
		label: 'Role of the animal in the household?',
		label_hu: 'Az állat szerepe a háztartásban',
		type: 'enum',
		values: [
			'child',
			'family member',
			'friend',
			'coworker',
			'non-equal partner',
			'pet',
			'other'
		],
		values_hu: [
			'gyerek',
			'családtag',
			'barát',
			'munkatárs',
			'nem egyenrangú társ',
			'házi kedvenc',
			'egyéb'
		],
		required: true
	},
	{
		field: 'available',
		label: 'Animal is available for...',
		label_hu: 'Az állattal részt venne',
		description: 'You can choose more options',
		description_hu: 'Többet is megjelölhet',
		type: 'multiple',
		values: [
			'questionnaire',
			'behaviour test at the department',
			'behaviour test in its home',
			'online behaviour test (done by the owner at home by a protocol/online help of the researcher)',
			'none of them',
		],
		values_hu: [
			'kérdőíves vizsgálatban',
			'viselkedésteszten a tanszéken',
			'viselkedésteszten otthon',
			'online viselkedésteszten (a gazda végzi el a tesztet a saját otthonában egy protokoll/a kutató online segítségével)',
			'nem egyenrangú társ',
			'nem vennék részt semmilyen kutatásban'
		],
		required: true
	},
	{
		field: 'external_resource_link',
		label: 'External Resource Link',
		type: 'link'
	},
	{
		field: 'comment',
		label: 'Comment',
	},
	{
		field: 'tags',
		label: 'Tags',
		nofilter: false,
		values: '/tag/list',
		type: 'tags',
		label_key: 'name'

	},
]

export const ANIMAL_LISTED_FIELDS_FLAT = [
	...ANIMAL_FIELDS_FLAT,
	...ANIMAL_DOG_FIELDS_FLAT,
	...ANIMAL_CAT_FIELDS_FLAT,
	...AUDIT_FIELDS_FLAT
]

const ANIMAL_FULL_FIELDS_FLAT = [
	...ANIMAL_FIELDS_FLAT,
	...ANIMAL_DOG_FIELDS_FLAT,
	...ANIMAL_CAT_FIELDS_FLAT,
]

export const ANIMAL_FULL_LISTED_FIELDS_FLAT_LOOKUP = [
	...ANIMAL_FULL_FIELDS_FLAT,
	...OWNER_FIELDS_FLAT.filter(f => (f.field !== '_id')).map(f => Object.assign({}, f, {
		field: `ownerId.${f.field}`,
		label: `Owner ${f.label}`,
		readonly: true // just in case B)
	})),
	...AUDIT_FIELDS_FLAT
]

export { ANIMAL_CAT_FIELDS_FLAT, ANIMAL_DOG_FIELDS_FLAT, ANIMAL_FULL_FIELDS_FLAT }
