import { AUDIT_FIELDS_FLAT } from './audit_fields'

export const OWNER_FIELDS_FLAT = [
	{
		field: '_id',
		label: 'ID',
		nofilter: true, // TODO ObjectId, filter must be augmented on server
		readonly: true
	},
	{
		field: 'ownerFirstName',
		label: 'First name',
		type: 'string',
		label_hu: 'Keresztnév',
		required: true
	},
	{
		field: 'ownerLastName',
		label: 'Last name',
		type: 'string',
		label_hu: 'Vezetéknév',
		required: true
	},
	{
		field: 'ownerEmail',
		label: 'Email address',
		type: 'email',
		label_hu: 'Email cím',
		description: 'Please write here an email address you actually use as we will invite you for participation in research through this.',
		description_hu: 'Kérem olyan email címet adjon meg, amelyen keresztül fel tudjuk venni Önnel a kapcsolatot kutatásban való részvétel kapcsán.',
		required: true
	},
	{
		field: 'phoneNumber',
		label: 'Phone number',
		type: 'string',
		label_hu: 'Telefonszám',
		required: false
	},
	{
		field: 'birthDate',
		label: 'Date of birth',
		type: 'string',
		label_hu: 'Születési dátum',
		required: true
	},
	{
		field: 'gender',
		label: 'Gender',
		type: 'enum',
		label_hu: 'Nem',
		values: ['male', 'female', 'other', 'do not want to add'],
		values_hu: ['férfi', 'nõ', 'egyéb', 'nem szeretnék válaszolni'],
		required: true
	},
	{
		field: 'Location',
		label: 'Location (name of your hometown)',
		type: 'string',
		label_hu: 'Lakóhely (település neve)',
		required: true
	},
	{
		field: 'english',
		label: 'Do you speak english?',
		type: 'enum',
		label_hu: 'Beszél angolul?',
		description: 'Foreign researchers also make behaviour tests at our department, so if you answer with yes for this question, you can get invitation from english speaking experimenters',
		description_hu: 'A tanszéken külföldi munkatársak is végeznek viselkedésteszteket, és amennyiben igennel válaszol, kaphat ilyen megkeresését is.',
		values: ['yes', 'no'],
		values_hu: ['igen', 'nem'],
		required: true
	},
	{
		field: 'preferredLang',
		label: 'What is your preferred language for communication with our researchers?',
		type: 'enum',
		label_hu: 'Milyen nyelven szeretne leginkább kommunikálni a tanszéki kutatások kapcsán?',
		values: ['hungarian', 'english'],
		values_hu: ['magyar', 'angol'],
		required: true
	},
	{
		field: 'numAdultsHousehold',
		label: 'Number of adult people in the household',
		type: 'number',
		description: 'Please, do not include yourself in this number',
		description_hu: 'Kérem, önmagát ne számolja bele',
		label_hu: 'Önnel egy háztartásban élõ felnõttek száma',
		required: true
	},
	{
		field: 'numChildrenHousehold',
		label: 'Number of children (<18 years) in your household',
		type: 'number',
		label_hu: 'Önnel egy háztartásban élõ gyermekek (18 év alatti) száma',
		required: true
	},
	{
		field: 'numDogsHousehold',
		label: 'Number of dogs in the household',
		type: 'number',
		label_hu: 'Önnel egy háztartásban élõ kutyák száma',
		description: 'Please, do not include the dog you are actually registering to this number',
		description_hu: 'Kérem, azt a kutyát, melyet most regisztrál, ne számolja bele',
		required: true
	},
	{
		field: 'numPreviousDogs',
		label: 'Number of previous own dogs',
		type: 'number',
		label_hu: 'Korábbi saját kutyák száma',
		description: 'Please, do not include your current dog',
		description_hu: 'Kérem, a jelenlegi kutyáját ne számolja bele',
		required: true
	},
	{
		field: 'external_resource_link',
		label: 'External Resource Link',
		type: 'link'
	},
	{
		field: 'comment',
		label: 'Comment',
	},
	{
		field: 'tags',
		label: 'Tags',
		nofilter: false,
		values: '/api/tag/list',
		type: 'tags',
		label_key: 'name'

	},
]

export const OWNER_LISTED_FIELDS_FLAT = [
	...OWNER_FIELDS_FLAT,
	...AUDIT_FIELDS_FLAT
]
